// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebase: {
    apiKey: 'AIzaSyCrq8s4R6SxGz6h0IxDTdYmJi-o75FT6ZU',
    authDomain: 'kanbanfire-626e3.firebaseapp.com',
    projectId: 'kanbanfire-626e3',
    storageBucket: 'kanbanfire-626e3.appspot.com',
    messagingSenderId: '780467127666',
    // appId: "1:780467127666:web:57996a8cb0c0bb257d09eb",
    // measurementId: "G-DFKTJ5YM1P"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
