import { Component, OnInit } from '@angular/core';
import * as mediaData from 'BalancedMediaDiet.json';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'bmd-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  diets: any;

  constructor(private util: UtilityService) {}

  ngOnInit(): void {
    this.diets = mediaData.diets;
  }

  scroll = (sectionID: string) => this.util.scroll(sectionID);
}
