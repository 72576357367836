import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsSource } from './news-source.model';

import * as mediaData from 'BalancedMediaDiet.json';

@Component({
  selector: 'news-source',
  templateUrl: './news-source.component.html',
  styleUrls: ['./news-source.component.scss'],
})
export class NewsSourceComponent {
  @Input() source: NewsSource;
  @Output() edit = new EventEmitter<NewsSource>();

  // sources: any = [];
  // newsSource: any;

  constructor(
    private route: ActivatedRoute // private location: Location
  ) {}

  ngOnInit() {
    console.log('the selected source in the modal: ', this.source);
    // this.sources = mediaData.sources;
    // const id = +this.route.snapshot.paramMap.get('id');
    // this.newsSource = this.sources.find((source) => source.id === id);
  }
}
