<article>
  <h1>A Balanced Media Diet for 2021</h1>
  <h4>How to be an informed citizen in an era of deception.</h4>

  <img class="food-pyramid"
      src="./../../assets/Balanced-Media-Diet.png" />
  <caption>
    A Media Diet Pyramid
  </caption>

  <p class="drop-cap">Welcome to the era of fake news.</p>

  <p>
    The number of media sources available to Americans has exploded over the past two decades with
    the growth of the internet. These myriad sources present the very same event in often
    dramatically different ways, which can be fascinating. The problem is, each source often labels
    all other sources as deceptive or even malicious by denouncing them as “fake news.”
  </p>

  <p>
    This creates a dilemma: Either accept one news source as the arbiter of truth and let it
    distinguish the fake from the genuine and thereby filter the news for you, or read broadly
    despite the warnings and trust your own intelligence to determine the veracity of the news you
    read.
  </p>

  <p>
    Choosing the first path — which many do — is creating increasingly distinct news bubbles in this
    country which in turn lead to social media “echo chambers” where a small and dwindling range of
    perspectives is available.
  </p>

  <p>This guide is for those choosing the second path.</p>

  <p>
    If you’re ready to acknowledge that no one news source is always right — about its take on every
    event or about which of its competitors’ coverage is “fake” — then you’re ready to acknowledge
    that we live in an era of deception and that it’s important and worthwhile to be conscious in
    your consumption of the news.
  </p>

  <p>
    This guide proposes a balanced <em>media diet</em> — a diverse set of media sources that will
    allow anyone with 30 minutes a day to become aware of the truth about current events most of the
    time.
  </p>

  <p>
    Just as there are food additives and chemicals that will make you sick, there are dense foods
    that boost your immune system; just as there are fad diets that will ultimately diminish your
    health, there is the time-tested notion of a balanced diet that identifies food groups to ensure
    any diet provides all the basic nutrients a body needs.
  </p>

  <p>
    This media diet identifies media food groups so as to balance not only the perspectives of the
    news one consumes but also the sources of the news. The goal is to ensure that all media
    nutrients are available to the consumer’s thoughts.
  </p>

  <p>
    This media diet identifies media food groups so as to balance not only the <em>perspective</em>s
    of the news one consumes but also the <em>source</em>s of the news. The goal is to ensure that
    all media nutrients are available to the consumer’s thoughts.
  </p>

  <p>
    Eating broadly leads to a healthy appetite and a sound balance within the body; reading broadly
    leads to a critical mind and a healthy awareness of the world around you. This isn’t to say it’s
    <em>easy</em> to embark on a media diet. On the contrary, just as keeping to a new food diet
    that challenges your cravings and eating habits, a media diet will challenge your biases, your
    cultural beliefs, your political habits, and your certainty about who the “good guys” and “bad
    guys” might be. But the potential rewards are just as great. If you <em>do</em> stick to a media
    diet for even just a couple of weeks, you will begin to develop something I call
    <em>media consciousness, </em>an awareness of the biases at play in each of the narratives
    you’re told about current events.
  </p>

  <p>
    Just as fine-tuning the foods you eat for maximum health isn’t a one-time adjustment, developing
    <em>media consciousness</em> is a lifelong journey, a path of inquiry and learning that
    continuously deepens our awareness of the world around us.
  </p>

  <p>
    <em>You can skip the following four-part introduction to the current state of American media and
      go right to: <a href="#diet-grid">the diet</a>, <a href="#food-groups">the food groups</a>, or
      <a href="#news-sources">the highlighted sources</a>.</em>
  </p>

  <h2>First, A Brief History of Media</h2>

  <p class="drop-cap">
    To begin, a quick discussion of how we got here. It is important to understand that a biased and
    chaotic news media is nothing new to this country. It has been generations since the media has
    been so diverse, but in the 1920s and 1930s it was common for a major city to have as many as a
    dozen daily newspapers. Each newspaper generally had a clear viewpoint and bias known to all:
    the “Republicans’ paper,” the “labor paper,” the “Italians’ paper,” and so on. Most people read
    only one newspaper, but it was quite easy for anyone to buy a half-dozen papers one day and get
    a sense of the range of debate on a particular issue.
  </p>

  <p>
    This diversity of sources began to dwindle in the 1960s as an era of media consolidation
    intensified and culminated in a period of deregulation in the 1990s and early 2000s. At the turn
    of the century, suddenly just six corporations published and broadcasted nearly all political
    debate in this country.
  </p>

  <p>
    The rise of the internet has pushed the pendulum back in the other direction. Every viewpoint
    once again has its own media source, or even dozen media sources. Every voice can be heard if
    one is interested in listening.
  </p>

  <p>
    But two things are different this time. First, there are even more accessible sources today: you
    don’t have to walk to the newsstand or an international news store to get a dozen different
    opinions, you just need an internet connection. Second, and far more important, this time around
    the corporate and government news organizations know what they had, and they want it back. They
    have no interest in another era of numerous media sources providing people with diverse,
    distinct narratives about events and ideas; they relish the power that comes with controlling
    what ideas and perspectives are published.
  </p>

  <h2>Second, A War for your Mind</h2>

  <p class="drop-cap">
    And that brings us to where we are today. On one hand, we have a profusion of news sources and a
    concomitant difficulty discerning the honest ones from the deceptive ones. On the other hand, we
    have large legacy media corporations who desperately want to put the genie back in the bottle
    and regain control of the narrative. Much as there are fast food conglomerates that use
    advertisements to coerce you to eat one way or another, these media corporations would like
    nothing better than to convince you that the world was better when there were fewer sources,
    that it’s inconvenient to read a diversity of news sources, and that <em>they’re</em> best
    suited to determine for you which news sources are trustworthy and which are “fake.”
  </p>

  <blockquote class="kq">
    <p id="02ec"
        class="kr ks fn au kt ku kv kw kx ky kz jj ax"
        data-selectable-paragraph="">
      “Any dictator would admire the uniformity and obedience of the American media”
    </p>
    <p id="41c3"
        class="kr ks fn au kt ku kv kw kx ky kz jj ax"
        data-selectable-paragraph="">
      — Noam Chomsky, before the advent of the internet
    </p>
  </blockquote>

  <p>
    Put simply, there’s a war on for your mind. The power to control a society’s narrative about its
    present is tantamount to controlling that society’s future.
  </p>

  <p>
    The legacy media channels, from FOX to CNN to NPR, serve the type of news they think should be
    sufficient for all of us: a sprinkling of hard news on top of scandals, soundbites from pundits
    with uniform viewpoints, “infotainment” with little news content, “hot takes” from celebrities,
    and one-sided analysis of events that are presented primarily to cause outrage. What news this
    coverage provides consists almost exclusively of what I label
    <strong class="iq co">party politics, </strong>contributing news from only two of the media food
    groups.
  </p>

  <p>
    But worse than the shallowness of each channel’s analysis is the narrowness of each channel’s
    perspective, and the implication that its narrow perspective isn’t only valid but is<em>
      the only</em>
    valid perspective. Again, the label of “fake news” is peppered explicitly and implicitly
    throughout this type of media coverage these days to disparage other sources and to demand that
    we view their opinion about what is important and their version of events as<em> the truth</em>,
    and everything else as biased, manipulated, or simply “fake.”
  </p>

  <h2>Third, The Remedy</h2>

  <p class="drop-cap">
    With food, any diet consisting of just one single food repeated over and over will lead to
    disease. Similarly, any one news media source consumed exclusively becomes disinformation. Even
    spending a little more time to watch two channels of the same food group provides a broader
    perspective on things, but given the media consolidation mentioned above that transpired over
    the last half century, even this effort inevitably leaves one with a narrow view of the world.
  </p>

  <p>
    To get something approaching a complete sense of our world today, we have to explore independent
    media as well as corporate media, left-leaning media as well as right-leaning media, foreign
    news sources and domestic government propaganda, corporate PR and “native advertising,” and
    delve at least occasionally into deeper systemic analysis of our political system.
  </p>

  <p>
    Reading broadly and discerning the truth from such a cornucopia of voices might sound like an
    impossible task, but from my experience a carefully chosen, balanced diet can be not only
    manageable but also fascinating and invigorating to our minds. I propose that a broad diet
    confers intellectual vitality as well as a more accurate sense of what is going on in one’s
    community, country, and world — and that it ultimately paves a path to a healthy political
    discourse for us all.
  </p>

  <h2>Fourth, What Happens When We Stay in Thought Bubbles</h2>

  <p class="drop-cap">
    It doesn’t take an exhaustive review of history to find cases where great tragedies have
    befallen nations when the majority of the people have been continuously deceived by a single
    group of media sources. The Iraq War, which killed over a million innocent people and cost
    trillions of dollars, is one very recent such case. The Nazi holocaust is another. Tiananmen
    Square. The list goes on. Rising to the mental challenge of reading diverse media sources is, I
    believe, a worthwhile endeavor.
  </p>

  <p>
    With such momentous events happening all around us these days — the coronavirus pandemic, the
    2020 election, the protests at the capitol building — it is more important than ever to have a
    balanced and broad understanding of these events if we are to avoid catastrophic error in how we
    understand the country we live in.
  </p>

  <p>
    I’ve been writing about American politics since 2003, when I started an online political news
    magazine. The magazine’s second issue was titled “Big Media,” and it specifically covered our
    nation’s dwindling number of large news sources and the ongoing consolidation that the internet
    was just beginning to counteract. I’ve been tracking independent and corporate news sources ever
    since, and I’ve learned that nearly every news source has something to offer.
  </p>

  <p>
    What if we each read a balanced mix of different voices and trusted our minds to distinguish the
    truth from the propaganda? What if we challenged our own confirmation biases? What if we
    confronted our fear of unfamiliar ideas? We might be able to talk to a wider array of people, we
    might pause to contemplate the complexity of pressing issues, we might understand the world more
    deeply.
  </p>

  <p>A balanced media diet presents these possibilities.</p>

  <p>
    Finally, I have to say that while I’ve spent hours reading, watching, and listening to sources
    of countless mindsets, viewpoints, and persuasions, and I can present a fairly broad-based
    background on the topic of news media — if you really want to get a firm grasp on how our media
    has functioned over the past century, you must read Noam Chomsky’s
    <em>Manufacturing Consent</em> (co-authored with Edward Herman) and research
    <a href="https://citizentruth.org/operation-mockingbird-cia-media-manipulation/">Operation
      Mockingbird</a>, a government project that placed CIA agents at major American news
    organizations. And you
    should know that President Obama in 2017
    <a
        href="https://foreignpolicy.com/2013/07/14/u-s-repeals-propaganda-ban-spreads-government-made-news-to-americans/">signed
      a bill</a>
    making government propaganda inside the US legal for the first time. I’m writing a book about
    this topic as well, which I plan to publish this year.
  </p>

  <p class="drop-cap">
    Now that we understand where we are, how we got here, and why it’s worth rising to the challenge
    of digesting diverse news media, here is the Balanced Media Diet for 2021.
  </p>

  <p>
    Below, in order, are the six media food groups, the diet itself, and the 40 highlighted media
    sources of diverse nutritious value. Enjoy!
  </p>

  <p>Please feel free to send me any suggestions you have for improving it.</p>

  <food-groups></food-groups>

  <diet-grid></diet-grid>

  <news-sources></news-sources>

  <hr />

  <p class="drop-cap">
    Ultimately the challenge of the diet is to consciously consider news rather than to simply
    consume whatever is thrown in front of you. When you begin to trust your own mind, your own
    heart, or your own intuition — whatever it is you use as a guide in the most important parts of
    your life — you will be able to discern honest and unbiased information as it comes before you.
    You’ll be wrong sometimes, but you’ll get better at it, and you’ll start to have a memory for
    these things. This is<em> media consciousness</em>.
  </p>

  <p>
    When you remember that the <em>New York Times</em> was in favor of the Iraq War and repeatedly
    published government lies about Iraq’s “weapons of mass destruction” — and you realize that the
    big New York paper has actually been in favor of every war for the last seven decades — you’ll
    realize it shouldn’t be your only source of news when you want unbiased information about a
    potential new use America’s deadly military. Sources that have failed you in the past likely
    will fail you again. Sources that have had value in the past likely will prove their value
    again.
  </p>

  <p>
    <em>Media consciousness</em>, which begins with a commitment to a media diet, is not a fixed
    goal but rather a lifelong path of growth, and this path will change and evolve as you do. Your
    perspective will change with the incessantly changing events of your life. Indeed, all things
    change. Media sources themselves evolve, grow, and improve or degrade in quality. This diet you
    have before you today will evolve as well — to adjust as the perspective and quality of its
    sources change. This diet is for 2021, and it differs slightly from
    <a href="/a-balanced-media-diet-for-2019-81b4c6abc683">2019’s</a>.
  </p>

  <p>Enjoy, and may your journey be fruitful.</p>
</article>
