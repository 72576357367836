<a name="food-groups"></a>

<h2>THE SIX MEDIA FOOD GROUPS FOR 2021</h2>

<p>
  <span>1.</span><strong class="iq co"> Foreign News. </strong>This food group includes any and all
  news and viewpoints originating from outside the United States, particularly from non-English
  speaking countries. Consuming media from this group is critical for reaching an informed opinion
  not just as a citizen of the country but as a member of this human race.
  <em class="jt">10% of the 2021 diet.</em>
</p>
<blockquote class="lz ma mb">
  <p>
    <strong class="iq co">Party Politics</strong> — The next pair of media sources together look at
    the daily jostling over legislation and power among elected officials in Washington, DC and
    state capitols. From who’s placed on what congressional committees, to court confirmation
    hearings, to approval ratings and budgeting, this is <em class="fn">party politics</em>. There
    are also usually one or two scandals for each party at any given time that keep the party
    politics news cycle flowing; these days, as we’re in the
    <a href="https://en.wikipedia.org/wiki/First_Red_Scare">third red scare</a>, news about
    politicians’ interactions with Russia, for instance, provides endless scandal material. These
    food groups constitute 90% of corporate mainstream political news.
  </p>
</blockquote>

<p>
  <span>2.</span><strong class="iq co"> Party Politics (Republican).</strong> News and viewpoints
  that follow the daily and weekly news cycle of events, scandals, and legislation on the Republican
  side. Important to understanding how news is packaged to inform and outrage conservatives.
  Generally the blame is placed on the other party and there is little deeper analysis.
  <em class="jt">20% of the 2021 diet.</em>
</p>

<p>
  <span>3.</span><strong class="iq co"> Party Politics (Democrat). </strong>News and viewpoints that
  follow the daily and weekly news cycle of events, scandals, and legislation on the Democratic
  side. Important to understanding how news is packaged to inform and outrage liberals. Generally
  the blame is placed on the other party and there is little deeper analysis.
  <em class="jt">20% of the 2021 diet.</em>
</p>
<blockquote class="lz ma mb">
  <p>
    <strong class="iq co">True Politics</strong> — The next pair of media sources looks beyond the
    parties and considers the underlying guiding political philosophies and principles that form the
    historical differences between conservatives and liberals, libertarians and progressives,
    capitalists and socialists. It has been said that all politics — for at least the past 1000
    years — can be viewed as originating from one of three impulses: 1.
    <em class="fn">progressive</em>, the impulse to spread wealth and power more broadly among
    people; 2. <em class="fn">reactionary</em>, aiming to concentrate wealth and power more
    narrowly; and 3. <em class="fn">conservative</em>, aiming to keep the distribution of wealth and
    power as it is. The push and pull of these three impulses is true politics. It should be
    mentioned that the great overarching trend — the long arc of history — has been bending in a
    progressive direction since 1215 and the signing of Magna Carta. <br />
    Note: The terms “Radical” or “Principled” are used sometimes instead of “True” for this layer of
    news coverage, but the former to me tends to have too negative a connotation, and the latter too
    positive, so I mostly use “True.”
  </p>
</blockquote>

<p>
  <span>4.</span><strong class="iq co"> True Politics (Progressive). </strong>News and analysis that
  will point out flaws in both parties and that will sometimes suggest that the parties agree about
  most things and feign disagreement. Progressive analysis includes
  <em class="jt">class analysis </em>that acknowledges that all Americans suffer when there are wars
  overseas, no universal healthcare, starvation wages, polluted air and water, and crippling debt.
  Progressive True Politics sources tend to be marginalized by Party Politics with these labels:
  “radical”, “socialist,” “far-left,” “communist.” <em class="jt">20% of the 2021 diet.</em>
</p>

<p>
  <span>5.</span><strong class="iq co"> True Politics (Libertarian &amp; Conservative).
  </strong>News and analysis
  that will point out flaws in both parties and that will sometimes suggest that the parties agree
  about most things and feign disagreement. Libertarian perspectives include
  <em class="jt">analysis of privacy and liberty </em>that critiques the encroaching surveillance
  state, worsening militarism, and shrinking set of guaranteed personal rights. Conservative
  perspectives include <em class="jt">attention to founding principles</em> and favor more
  deliberate consideration before advocating systemic change. Words used by Party Politics sources
  to marginalize and silence these sources: “radical,” “fascist,” “alt-right,” “nazi.”
  <em class="jt">20% of the 2021 diet.</em>
</p>
<blockquote class="lz ma mb">
  <p>
    <strong class="iq co">Deep Politics</strong> — This final media food group includes reporting on
    the political system as a whole, considering both parties and their principles, and also
    inspecting the generally allowed parameters of debate and discussion. Even a casual observer of
    American politics will notice that important policies remain largely unaffected by elections
    even if the party in power changes. Deep politics analyzes the political decisions and
    disagreements occurring in this permanent political establishment, which is generally called the
    “permanent state” or the “deep state.” Deep political analysis also contemplates covert
    agreements between the political parties and considers conspiracies, both those discussed openly
    in the mainstream media (alleged Russian election interference; 9/11 hijackings; etc.) and those
    not generally discussed in the mainstream media (election fraud; assassinations; climate
    engineering; “false flag” events and faked acts of foreign aggression; CIA-backed coups, etc.).
  </p>
</blockquote>

<p>
  <span>6.</span><strong class="iq co"> Deep Politics. </strong>News and analysis covering the
  underlying power structures of our country and the crucial decisions about things like war,
  energy, the money supply, and surveillance that are made largely by unelected officials in
  intelligence organizations, the Pentagon, and executive departments. Words used by both Party
  Politics and True Politics sources to marginalize and silence these Deep Politics sources:
  “paranoid,” “wacky,” “conspiracy theorist.” <em class="jt">10% of the 2021 diet.</em>
</p>
<h1>THE DIET</h1>

<p>
  <span>Y</span>ou’ve gotten this far, so you have both an interest and a bit of time to devote to a
  balanced awareness of our news media and the events and ideas shaping our world. Thank you and
  congratulations. Greater consciousness is right around the corner.
</p>

<p>
  Here’s how it works: You will read a different set of news sources each day of the week, as
  specified in the chart below. For each source, on each day, you will scroll down to the list of
  Highlighted Sources for 2021 and click to read, watch, or listen to the day’s sources. You can
  bookmark the sites in your browser so you don’t have to keep coming back to this page.
</p>

<h2>Three Levels</h2>

<p>
  The diet has three levels to fit your available time. If you haven’t been conscious of your media
  consumption before, start with 30 minutes a day; it will have an enormous impact on your awareness
  of the country and world around you. After just two weeks, you will begin to have an entirely
  different experience watching and reading news media.
</p>

<p>
  If you’re already consuming a lot of news media from various sources, or if you’re a professional
  journalist, politician, or professor, or if for any other reason you simply want an express
  journey to a broader understanding of our political and economic world, spend more time: Go on the
  “Journalist” or “Media Critic” diet, which offer more depth and a greater range of perspectives
  from quality journalists situated all across our media landscape.
</p>
<ol class="">
  <li>BASIC. “Conscious Citizen.” (30 minutes daily)</li>
  <li>ADVANCED. “Journalist.” (90 minutes daily)</li>
  <li>EXPERT. “Media Critic.” (180 minutes daily)</li>
</ol>

<p>There’s a fourth option too, for when you need to focus on other parts of life:</p>

<p>4. UNPLUGGED. “On a Media Fast.” (1 hour weekly)</p>

<p>
  <span>H</span>ere it is! I spent many hours reviewing sources and balancing the diet across the
  days of the week and across perspectives. All forty sources are detailed, with links provided,
  below the chart.
</p>

<p>The Balanced Media Diet for 2021:</p>
<br />
The Balanced Media Diet for 2021. Each source’s color matches its food group on the pyramid. ‘$’
indicates the source has a partial paywall.
