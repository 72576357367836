<div id="the-author"
    class="main-content text-center">
  <div id="author-photo"
      class="footer-block">
    <img src="../../assets/Tony+Brasunas-med.jpg">
  </div>
  <div id="author-info"
      class="footer-block">
    <h1>THE FOUNDER</h1>
    <p>
      <span class="highlight-underline">TONY BRASUNAS</span> is a nonpartisan freelance journalist
      who has been researching disinformation and media censorship for two decades. <em>Red White &
        Blind</em>, his second book, published in 2023, unearths a century of propaganda and
      censorship
      at the heart of American mainstream news media, and presents the groundbreaking path of a
      Balanced Media Diet to reach a state of sanity called <em>media consciousness</em>.
    </p>
    <p>Many other media and political researchers have contributed to the creation of these diets,
      and we welcome your contributions. Please reach out
      at <a href="mailto:info@balancedmediadiet.com">info@balancedmediadiet.com</a>.
      The goal is to gently force ourselves out of the echo chambers of our own confirmation bias,
      and to encourage others to do
      the same.
    </p>

  </div>

</div>


<div id="the-book"
    class="main-content text-center">

  <div id="the-book-image"
      class="footer-block mobile-only">
    <img src="../../assets/Red-White-Blind-book_shadowed-small.png">
  </div>

  <div id="the-book-info"
      class="footer-block">
    <h1>THE BOOK</h1>
    <p>
      Why were the sordid deeds of Jeffery Epstein concealed for two decades? Why was inquiry into
      the origins of COVID-19 suppressed? Why are social media sites silencing scientists, doctors,
      journalists, and scholars?
    </p>
    <p>
      This Balanced Media Diet emerged through a censored journalist's work
      to unearth censorship and propaganda in America today.
      <i>Red, White & Blind</i> presents not only the research
      that undergirds the Diet but countless truths about social media and
      mainstream news that every American should know.
    </p>
    <button nz-button
        nzType="primary"><a target="_blank"
          href="http://www.RedWhiteAndBlind.com">Read a Free Excerpt</a></button>
  </div>
  <div id="the-book-image"
      class="footer-block desktop-only">
    <a href="http://www.RedWhiteAndBlind.com"
        target="_blank"><img src="../../assets/Red-White-Blind-book_shadowed-small.png"
          border="0"></a>
  </div>

</div>


<div id="footer">
  <div>Content © 2023 <a href="http://www.tonybrasunas.com"
        target="_blank">Tony&nbsp;Brasunas</a>
  </div>
  <div id="social-icons">
    <a href="http://twitter.com/redwhiteblind"
        target="_blank">
      <img src="../../assets/buttons/twitter-TRANSP.png">
    </a>
    <a href="http://youtube.com/@redwhiteandblind"
        target="_blank">
      <img src="../../assets/buttons/youtube-TRANSP.png">
    </a>
    <a href="http://rumble.com/redwhiteandblind"
        target="_blank">
      <img src="../../assets/buttons/rumble-TRANSP.png">
    </a>
    <a href="hhttp://redwhiteandblind.substack.com/"
        target="_blank">
      <img src="../../assets/buttons/substack-TRANSP.png">
    </a>
    <a href="http://facebook.com/tonybrasunas"
        target="_blank">
      <img src="../../assets/buttons/facebook-TRANSP.png">
    </a>
  </div>
  <div>
    Website Design by <a href="https://www.zackvieira.com/"
        target="_blank">Zack&nbsp;Vieira</a>
  </div>
</div>
