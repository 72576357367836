import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { NewsSource } from '../news-source/news-source.model';
import { NewsSourceComponent } from '../news-source/news-source.component';

// import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

import * as mediaData from 'BalancedMediaDiet.json';
import { NzModalService } from 'ng-zorro-antd/modal';

/* Improve the optimistic updates by maintaining consistent state while data updates */
/* FIRESTORE */
// const getObservable = (collection: AngularFirestoreCollection<NewsSource>) => {
//   const subject = new BehaviorSubject([]);
//   collection.valueChanges({ idField: 'id' }).subscribe((val: NewsSource[]) => {
//     subject.next(val);
//   });
//   return subject;
// };

@Component({
  selector: 'diet-grid',
  templateUrl: './diet-grid.component.html',
  styleUrls: ['./diet-grid.component.scss'],
})
export class DietGridComponent implements OnInit {
  @Input() dietId: any;

  title = 'balanced-media-diet';
  sources: any = [];
  diet: any;
  diets: any = [];
  weekdays: any = [
    { name: 'Sunday', day: 0 },
    { name: 'Monday', day: 1 },
    { name: 'Tuesday', day: 2 },
    { name: 'Wednesday', day: 3 },
    { name: 'Thursday', day: 4 },
    { name: 'Friday', day: 5 },
    { name: 'Saturday', day: 6 },
  ];

  isSourceDisplayed = false;
  selectedNewsSource: any;

  // todo = getObservable(this.store.collection('todo'));
  // inProgress = getObservable(this.store.collection('inProgress'));
  // done = getObservable(this.store.collection('done'));

  // private store: AngularFirestore
  constructor(private modal: NzModalService, private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.diet = mediaData.diets.find((diet) => diet.id === this.dietId);
    this.configureDiet();
  }

  configureDiet(): void {
    /*
     * Type:        Network | Magazine | Journalist
     * Category:    Corporate | Independent
     * FoodGroup:   Foreign | Party | True | Deep
     * Viewpoint:   Democrat | Republican | Conservative | Libertarian | Progressive | Russian | Latin, etc.
     */

    this.sources = mediaData.sources;
    console.log('the diet to work with is ', this.diet);

    /* @TODO THIS WHOLE FUNCTION SHOULD BE DONE IN THE BACKEND
       API response should arrive as displayable array of objects to 
       minimize frontend processing here
    */
    this.diet.days = [];
    this.diet.newsSources.forEach((source) => {
      // console.log('.. and the source to work with is ', source);
      /* Create a new array for each day or add the source to the day's existing array */
      this.diet.days[source.day] = this.diet.days[source.day]
        ? this.diet.days[source.day].concat([this.getSource(source.sourceId)])
        : [this.getSource(source.sourceId)];
      // console.log('The diet of that day is now', this.diet.days[source.day]);
      // console.log('The day is now', this.diet.days[source.day]);
    });
    console.log('the diet and its days are now', this.diet);
    /* Pad days lists and add Weekday */
    this.diet.days.forEach((day, index) => {
      if (day.length < 2) day.push([]);
      const dayOfWeek: string = this.weekdays.find((wd) => wd.day === index).name;
      day.weekday = dayOfWeek;
    });
  }

  getSource(sourceId: number): string {
    const source = this.sources.find((s) => s.id === sourceId);
    source.label = this.getLabel(source);
    // console.log('Got this source', source);
    return source;
  }

  getLabel(source: NewsSource): string {
    /* Assign [Foreign | Deep | True | Party Right | Party Left | Newspaper ] */
    if (source.id === 125) return 'newspaper';
    if (source.viewpoint === 'Democrat') return 'party-politics-left';
    if (source.viewpoint === 'Republican') return 'party-politics-right';
    if (source.foodGroup === 'Foreign Politics') return 'foreign-politics';
    if (source.foodGroup.includes('Deep')) return 'deep-politics';
    return 'true-politics';
  }

  showDetailsModal(newsSource): void {
    // this.selectedNewsSource = newsSource;
    // this.isSourceDisplayed = true;
    const modal = this.modal.create<NewsSourceComponent, NewsSource>({
      nzTitle: null,
      nzFooter: null,
      nzWidth: 650,
      nzCentered: true,
      nzClassName: 'news-source-modal',
      nzContent: NewsSourceComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        source: newsSource,
      },
    });
    // const instance = modal.getContentComponent();
  }

  handleOk(): void {
    this.isSourceDisplayed = false;
  }

  handleCancel(): void {
    this.isSourceDisplayed = false;
  }

  /* TODO List - FIRESTORE DEMO */
  // newNewsSource(): void {
  //   const dialogRef = this.dialog.open(NewsSourceDialogComponent, {
  //     width: '270px',
  //     data: {
  //       newsSource: {},
  //     },
  //   });
  //   dialogRef
  //     .afterClosed()
  //     .subscribe((result: NewsSourceDialogResult) =>
  //       this.store.collection('todo').add(result.newsSource)
  //     );
  // }
  // editNewsSource(list: 'done' | 'todo' | 'inProgress', newsSource: NewsSource): void {
  //   const dialogRef = this.dialog.open(NewsSourceDialogComponent, {
  //     width: '270px',
  //     data: {
  //       newsSource,
  //       enableDelete: true,
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe((result: NewsSourceDialogResult) => {
  //     if (result.delete) {
  //       this.store.collection(list).doc(newsSource.id).delete();
  //     } else {
  //       this.store.collection(list).doc(newsSource.id).update(newsSource);
  //     }
  //   });
  // }

  // drop(event: CdkDragDrop<NewsSource[]>): void {
  //   if (event.previousContainer === event.container) {
  //     return;
  //   }
  //   const item = event.previousContainer.data[event.previousIndex];
  //   this.store.firestore.runTransaction(() => {
  //     const promise = Promise.all([
  //       this.store.collection(event.previousContainer.id).doc(item.id).delete(),
  //       this.store.collection(event.container.id).add(item),
  //     ]);
  //     return promise;
  //   });
  //   transferArrayItem(
  //     event.previousContainer.data,
  //     event.container.data,
  //     event.previousIndex,
  //     event.currentIndex
  //   );
  // }
}
