import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewsSource } from '../news-source/news-source.model';

@Component({
  selector: 'news-source-dialog',
  templateUrl: './news-source-dialog.component.html',
  styleUrls: ['./news-source-dialog.component.scss'],
})
export class NewsSourceDialogComponent {
  private backupNewsSource: Partial<NewsSource> = { ...this.data.newsSource };

  constructor(
    public dialogRef: MatDialogRef<NewsSourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewsSourceDialogData
  ) {}

  cancel(): void {
    this.data.newsSource.name = this.backupNewsSource.name;
    this.data.newsSource.description = this.backupNewsSource.description;
    this.dialogRef.close(this.data);
  }
}

export interface NewsSourceDialogData {
  newsSource: Partial<NewsSource>;
  enableDelete: boolean;
}

export interface NewsSourceDialogResult {
  newsSource: NewsSource;
  delete?: boolean;
}
