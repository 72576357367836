<div class="news-source-inner">
  <img class="bmd-nameplate"
      src="../../assets/main_nameplate-Balanced-Media-Diet.png" />
  <h1 class="text-bmd-red">{{ source.name }}</h1>
  <img alt="Media Source - {{ source.name }}"
      class="media-source-logo"
      src="../../assets/news-sources/{{ source.imageName }}" />
  <p [innerHTML]="source.description"></p>
  <span class="foodgroup">
    <strong>Media Food Group:</strong> {{ source.foodGroup }}
  </span>
  <span class="viewpoint">
    <strong>Viewpoint:</strong> {{ source.viewpoint }}
  </span>
  <a *ngIf="source.linkToRead"
      href="{{ source.linkToRead }}"
      target="_blank">
    <h2 class="visit-link">Read ></h2>
  </a>
  <a *ngIf="source.linkToWatch"
      href="{{ source.linkToWatch }}"
      target="_blank">
    <h2 class="visit-link">Watch ></h2>
  </a>
  <a *ngIf="source.linkToListen"
      href="{{ source.linkToListen }}"
      target="_blank">
    <h2 class="visit-link">Listen ></h2>
  </a>
</div>
