<mat-form-field>
  <mat-label>Title</mat-label>
  <input matInput
      cdkFocusInitial
      [(ngModel)]="data.newsSource.name" />
</mat-form-field>

<mat-form-field>
  <mat-label>Description</mat-label>
  <textarea matInput
      [(ngModel)]="data.newsSource.description"> </textarea>
</mat-form-field>

<div mat-dialog-actions>
  <button mat-button
      [mat-dialog-close]="{ newsSource: data.newsSource }">OK</button>
  <button mat-button
      (click)="cancel()">Cancel</button>
  <button *ngIf="data.enableDelete"
      mat-fab
      color="primary"
      aria-label="Delete"
      [mat-dialog-close]="{ newsSource: data.newsSource, delete: true }">
    <mat-icon>delete</mat-icon>
  </button>
</div>
