<div class="logo pointer"
    (click)="scroll('home-header')">
  <img src="../assets/main_nameplate-Balanced-Media-Diet.png">
</div>
<ul nz-menu
    nzMode="horizontal">

  <li nz-menu-item
      *ngFor="let diet of diets"><a (click)="scroll(diet.title)">{{ diet.title }}</a></li>

  <li nz-menu-item
      (click)="scroll('the-book')"
      class="bmd-button">The Book</li>
</ul>
