<!-- <nz-layout class="outer-layout"> -->

<nz-layout>

  <nz-header>
    <bmd-nav></bmd-nav>
  </nz-header>

  <nz-content>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </nz-content>

  <nz-footer>
    <bmd-footer></bmd-footer>
  </nz-footer>

</nz-layout>

<!--- USE FOR RIGHT SIDE COLLAPSIBLE MENU WHEN NEEDED
        Also turn on .ant-layout-sider-zero-width-trigger rule in styles  
  <nz-sider nzCollapsible
      nzReverseArrow
      nzBreakpoint="lg"
      [nzCollapsedWidth]="0">
    <div class="logo"></div>
    <ul nz-menu
        nzTheme="light"
        nzMode="inline">
      <li nz-menu-item>
        <span nz-icon
            nzType="user"></span>
        <span>nav 1</span>
      </li>
      <li nz-menu-item>
        <span nz-icon
            nzType="video-camera"></span>
        <span>nav 2</span>
      </li>
      <li nz-menu-item>
        <span nz-icon
            nzType="upload"></span>
        <span>nav 3</span>
      </li>
      <li nz-menu-item>
        <span nz-icon
            nzType="user"></span>
        <span>nav 4</span>
      </li>
    </ul>
  </nz-sider>


</nz-layout>
  -->
