import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() {}

  /* Browser Scroll To Id */
  scroll(sectionId: string) {
    const element: HTMLElement = document.getElementById(sectionId);
    const viewportWidth = document.documentElement.clientWidth;
    const mobile = viewportWidth < 767;
    const navHeaderHeight = mobile ? 160 : 100;
    const targetScrollPosition = element.offsetTop - navHeaderHeight;

    window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
  }
}
