<a name="{{ diet.title }}"></a>

<div id="{{ diet.title }}"
    class="diet-container">

  <div class="diet-title">
    <div class="titles">
      <h1 class="text-bmd-red margin-bottom-0px">{{ diet.title }}</h1>
      <h2 class="text-bmd-blue">"{{ diet.subtitle }}"</h2>
    </div>
    <div class="diet-time">
      [ {{ diet.time}} ]
    </div>
  </div>
  <!-- <button (click)="newNewsSource()"
        mat-button>
      <mat-icon>add</mat-icon> Add Source
    </button> -->

  <section class="grid-headers desktop-only">
    <h3></h3>
    <h3>Sunday</h3>
    <h3>Monday</h3>
    <h3>Tuesday</h3>
    <h3>Wednesday</h3>
    <h3>Thursday</h3>
    <h3>Friday</h3>
    <h3>Saturday</h3>
  </section>


  <section class="grid-diets">
    <div class="diet-description small-text margin-right-15px">
      <em [innerHTML]="diet.description"></em>
    </div>
    <div *ngFor="let day of diet.days"
        class="outer-tile">
      <h2 class="mobile-only">{{ day.weekday }}</h2>
      <div class="inner-grid">
        <div *ngFor="let newsSource of day"
            class="news-source-cell"
            (click)="showDetailsModal(newsSource)">
          <img class="news-source-image pointer"
              src="../../assets/news-sources/{{ newsSource.imageName }}">

          <img class="news-source-label"
              src="../../assets/labels/{{ newsSource.label }}.png">
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <nz-modal [(nzVisible)]="isnewsSourceDisplayed"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
    [nzContent]="NewsSourceComponent"
    [nzData]="selectedNewsSource">

  IN THE MODAL

</nz-modal> -->

<!-- <ng-container *nzModalContent></ng-container> -->
