<a name="news-sources"></a>
<h2>THE HIGHLIGHTED NEWS SOURCES FOR 2021</h2>

<p>
  <span>A</span>fter extensive research and a weighted rating selection routine, I chose the
  following 40 <strong class="iq co">networks</strong>, <strong class="iq co">magazines</strong>,
  and <strong class="iq co">individual journalists </strong>for your balanced 2021 diet. Feel free
  to supplement them with your own favorites, but always pay attention to the overall balance of
  your diet.
</p>

<h2 id="b9a8"
    class="jv jw fn au jx jy jz gm ka kb kc gp kd gq ke gs kf gt kg gv kh gw ki gy kj kk dw"
    data-selectable-paragraph="">
  Networks
</h2>

<p>
  <a href="https://www.aljazeera.com/"><strong class="iq co">Al Jazeera</strong></a> — An
  international Arab news network with a full English version. Relatively open news coverage of
  international events. Based in Qatar, owned by a Qatari public-private partnership with
  significant funding from the Qatari government.<br />
  <strong class="iq co">Media Food Group:</strong> Foreign Politics<br />
  <strong class="iq co">Viewpoint:</strong> International, Middle Eastern, Arab<br />
  <a href="https://www.aljazeera.com/">WATCH &gt;</a>
</p>

<p>
  <a href="http://www.chinadaily.com.cn/"><strong class="iq co">China Daily</strong></a> — The major
  national English-language Chinese newspaper. Covers international news, with a focus on Chinese
  affairs. Founded and funded by the Chinese government. Based in China. (If you’re concerned about
  foreign propaganda, please consider that all media — particularly government or corporate funded
  media — is propaganda. See the description of RT, below, to be inspired to read foreign media
  nonetheless.) <br />
  <strong class="iq co">Media Food Group:</strong> Foreign Politics<br />
  <strong class="iq co">Viewpoint: </strong>International, Chinese<br />
  <a href="http://www.chinadaily.com.cn/">READ &gt;</a>
</p>

<p>
  <a href="https://www.c-span.org/"><strong class="iq co">C-SPAN</strong></a> — A network created
  out of a 1979 settlement with cable companies, C-SPAN carries live and often unmoderated coverage
  of political events in Washington D.C. Sometimes this makes for compelling television, other times
  dull coverage, but in any case it is good for a balanced understanding of politics, albeit from an
  establishment, bipartisan perspective. Analysis stays within the accepted bounds of corporate
  media. Owned by the National Cable Satellite Corporation. Based in Washington, DC.<br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint</strong>: Republican &amp; Democrat<br />
  <a href="https://www.c-span.org/">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.foxnews.com/"><strong class="iq co">FOX News </strong></a>— The big
  Republican-leaning corporate political news network. Owned by FOX Corporation, with Rupert Murdoch
  as chairman and 39% ownership via a family trust. Based in New York.<br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Republican<br />
  <a href="https://www.foxnews.com/">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.msnbc.com/"><strong class="iq co">MSNBC </strong></a>— The big
  Democrat-leaning corporate political news network. Originally founded through a joint venture by
  Bill Gates’s Microsoft and GE’s NBC News which created the name “MS-NBC.” Now owned by giant media
  conglomerate Comcast. Based in New York.<br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Democrat<br />
  <a href="https://www.msnbc.com/">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.npr.org/"><strong class="iq co">NPR </strong></a>— The government-founded
  American radio network, now generally supported by corporations and foundations. At one point NPR
  and its affiliates were primarily supported by government funding, but that was phased out during
  the Reagan and Clinton years — the era of media deregulation. With its recently increasing
  corporate underwriting, NPR is too big and too heavily influenced by corporate forces to be
  considered independent. Strong establishment and Democrat bias. Based in Washington, DC.<br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Democrat<br />
  <a href="https://www.npr.org/stations/">LISTEN &gt;</a>
  <a href="https://www.npr.org/">READ &gt;</a>
</p>

<p>
  <a href="https://www.rt.com/"><strong class="iq co">RT </strong></a>— A major international news
  network funded in part by the Russian government. We are in the
  <a href="https://en.wikipedia.org/wiki/First_Red_Scare">Third Red Scare</a>, so you’re supposed to
  be scared of this network, but in truth it’s a great source of alternative perspectives that will
  broaden your mind. As you watch, think of the open-minded people in East Germany in 1970 listening
  to Voice of America; or the open-minded people in China today watching CNN. You’re smart enough to
  consider unfamiliar ideas, that’s the point of a media diet. In fact, most of the commentators on
  RT are brilliant Americans who were too outspoken for MSNBC’s corporate pro-war bias: Phil Donahue
  was fired by MSNBC for opposing the Iraq invasion; Jesse Ventura was fired by MSNBC for opposing
  the Iraq invasion; Ed Schultz was fired for talking too much about Bernie Sanders, etc. Why did
  MSNBC fire these progressives? Important to check it out. <br />
  <strong class="iq co">Media Food Group: </strong>Foreign Politics<br />
  <strong class="iq co">Viewpoint</strong>: International, Russian<br />
  <a href="https://www.rt.com/">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.telesurenglish.net/"><strong class="iq co">Telesur </strong></a>— An
  international news network funded by a coalition of Latin American countries to counter the
  influence of CNN. A great regular complement to an American’s media diet, Telesur tends to provide
  alternative viewpoint with a left-leaning or even socialist take on international events.<br />
  <strong class="iq co">Media Food Group: </strong>Foreign Politics<br />
  <strong class="iq co">Viewpoint:</strong> International, Latin American (Bolivia, Cuba, El
  Salvador, Venezuela)<br />
  <a href="https://www.telesurenglish.net/">WATCH &gt;</a>
</p>
<h1>Magazines &amp; Newspapers</h1>

<p>
  <a href="https://www.theatlantic.com/"><strong class="iq co">The Atlantic </strong></a>— A
  liberal-leaning political journal in print since the 1800s. After changing hands several times
  recently, it’s now owned by the Emerson Collective, a foundation started by billionaire Laurene
  Powell Jobs, former Apple CEO Steve Jobs’ widow. Based in Washington DC.<br />
  <strong class="iq co">Media Food Group:</strong> Independent Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Democrat<br />
  <a href="https://www.theatlantic.com/">READ &gt;</a>
</p>

<p>
  <a href="https://www.cnsnews.com/"><strong class="iq co">CNSNews</strong></a><strong
      class="iq co"> </strong>— A firmly conservative news magazine that was founded in the
  late 1990s by conservative media advocate L. Brent Bozell to counter “liberal bias” in the
  corporate media. Originally named “Conservative News Source,” CNS has since changed its name to
  Cybercast News Source. Owned by the Media Research Center. Based in Reston, Va.<br />
  <strong class="iq co">Media Food Group:</strong> Independent Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Republican<br />
  <a href="https://www.cnsnews.com/">READ &gt;</a>
</p>

<p>
  <a href="https://consortiumnews.com/"><strong class="iq co">Consortium News</strong></a> — A great
  source for deeper analysis of the current American political situation. It was one of the first
  internet-based news magazines, founded by Robert Parry in 1995. Parry was the journalist who broke
  the Iran-Contra Scandal in the 1980s, and he is one of many journalists who have (unfortunately)
  been fired by corporate media for taking a step too far investigating controversial topics. Based
  in Arlington, Va. <br />
  <strong class="iq co">Media Food Group:</strong> Independent Deep Politics<br />
  <strong class="iq co">Viewpoint:</strong> Nonpartisan<br />
  <a href="https://consortiumnews.com/">READ &gt;</a>
</p>

<p>
  <a href="https://www.democracynow.org/"><strong class="iq co">Democracy Now</strong></a> — A
  progressive television and radio show produced in New York by longtime left media personage Amy
  Goodman. Provides generally under-reported news on labor and social movements as well as coverage
  of foreign news with a focus on the effects of American sanctions and military strikes. <br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Progressive<br />
  <a href="https://www.democracynow.org/shows">WATCH &gt;</a>
  <a href="https://www.democracynow.org/">READ &gt;</a>
</p>

<p>
  <a href="https://www.economist.com/united-states/"><strong class="iq co">The
      Economist</strong></a>
  — A business-friendly international weekly with an establishment pedigree. Firmly centrist, its
  bias is in favor of capitalism and globalization, and since both American political parties now
  generally support these things too, it’s hard to say the Economist has a clear party preference,
  but in 2021 seems to have a slight Democrat bias. <br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Democrat<br />
  <a href="https://www.economist.com/united-states/">READ &gt;</a>
</p>

<p>
  <a href="https://www.forbes.com/"><strong class="iq co">Forbes</strong></a> — A
  conservative-leaning business and politics magazine and contributor platform founded in 1917 and
  run by a member of the Forbes family ever since. Unironically uses the nickname, “The Capitalist
  Tool.” An expansive array of contributors provide a wide array of perspectives. Owned now by Asian
  holding company Integrated Whale Media. Based in New Jersey. <br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Republican<br />
  <a href="https://www.forbes.com/">READ &gt;</a>
</p>

<p>
  <a href="https://www.globalresearch.ca/"><strong class="iq co">Global Research</strong></a> —
  Provides a trenchant critique of deep politics, focusing on the ramifications of American and
  Western foreign policy, American empire, terrorism, state-sponsored violence, and wars. Pulls no
  punches in appraising current events and American history. Based in Ottawa, Canada.<br />
  <strong class="iq co">Media Food Group:</strong> Independent Deep Politics<br />
  <strong class="iq co">Viewpoint:</strong> Nonpartisan<br />
  <a href="https://www.globalresearch.ca/">READ &gt;</a>
</p>

<p>
  <a href="https://ivn.us/"><strong class="iq co">IVN </strong></a>— A news platform dedicated to
  highlighting independent political news, the Independent Voter Network focuses on electoral reform
  and on events that reveal the political straitjacket of the two-party system.<br />
  <strong class="iq co">Media Food Group: </strong>Independent Party Politics<br />
  <strong class="iq co">Viewpoint: </strong>Nonpartisan<br />
  <a href="https://ivn.us/">READ &gt;</a>
</p>

<p>
  <a href="https://lockdownsceptics.org/"><strong class="iq co">Lockdown Skeptics</strong></a> — A
  Britain-based review of news that questions the justification of throttling worldwide lockdowns,
  offering some balance for those of us inured to corporate media’s incessant defense of these
  global restrictions. Often brilliant perspective pieces. Based in London.<br />
  <strong class="iq co">Media Food Group: </strong>Independent True Politics<br />
  <strong class="iq co">Viewpoint: </strong>Nonpartisan<br />
  <a href="https://lockdownsceptics.org/">READ &gt;</a>
</p>

<p>
  <a href="https://jacobinmag.com/"><strong class="iq co">Jacobin </strong></a>— A socialist-leaning
  progressive political magazine with a fearless attitude. Great for getting a balanced perspective
  on, say, the Bernie Sanders movement or the underlying goals of the more progressive members of
  Congress. Critiques of the Democratic Party from the left. Based in New York, NY.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Progressive<br />
  <a href="https://jacobinmag.com/">READ &gt;</a>
</p>
<p>
  <a href="https://www.nytimes.com/"><strong class="iq co">New York Times</strong></a><strong
      class="iq co"> </strong>($) — The most widely circulated Democrat-leaning newspaper.
  Exhaustive reporting of domestic and international events. The agenda-setter for left-leaning
  media, the “paper of record.” This prestigious organization was also the most important asset of
  Operation Mockingbird, which recruited journalists to work for the CIA. Owned primarily by the
  American Sulzberger family and Mexican billionaire Carlos Slim.
  <strong class="iq co"><em class="jt">Paywall after 3 articles.</em></strong><br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Democrat<br />
  <a href="https://www.nytimes.com/">READ &gt;</a>
</p>

<p>
  <a href="https://www.thenation.com/subject/politics/"><strong class="iq co">The
      Nation</strong></a><strong class="iq co"> </strong>($) — The country’s oldest weekly magazine.
  A longtime
  progressive news source still putting out trenchant analysis and great journalism, although
  currently more inline with Democrat talking points than in years past. Sometimes on the line
  between Party Politics and True Politics, but offering more Party Politics lately.
  <em class="jt">Paywall after 6 articles.</em><br />
  <strong class="iq co">Media Food Group: </strong>Independent Party Politics<br />
  <strong class="iq co">Viewpoint: </strong>Democrat<br />
  <a href="https://www.thenation.com/subject/politics/">READ &gt;</a>
</p>

<p>
  <a href="https://www.nationalreview.com/"><strong class="iq co">National Review</strong></a> — A
  traditional pillar of right-leaning thought, it was founded in the 1950s by William F. Buckley and
  has featured and strengthened a variety of strands of conservative thought over the decades.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint</strong>: Conservative<br />
  <a href="https://www.nationalreview.com/">READ &gt;</a>
</p>

<p>
  <a href="https://reason.com/"><strong class="iq co">Reason</strong></a> — A leading libertarian
  magazine, it was founded in 1968 as a mimeographed newsletter and has grown into an important
  journal with a refreshing perspective on politics and culture. In a time of intense
  two-dimensional political rancor, <em class="jt">Reason</em> offers a third dimension, one that is
  necessary when things like lockdowns and forced medical procedures are in the political discourse.
  Styles itself “journal of free minds and free markets.” Based in Los Angeles, Ca.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint</strong>: Libertarian<br />
  <a href="https://reason.com/">READ &gt;</a>
</p>

<p>
  <a href="https://www.youtube.com/channel/UCPWXiRWZ29zrxPFIQT7eHSA"><strong class="iq co">Rising
    </strong></a>— A daily news show produced by <em class="jt">The Hill</em> with a refreshing
  populist
  perspective and a balance of progressive and conservative thought. Co-hosts Krystal Ball
  (progressive) and Saagar Enjeti (conservative) analyze beltway and national politics, and when
  they get into principled debates with each other we have a model for engaged political discourse.
  Based in Washington, DC.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint</strong>: Bipartisan<br />
  <a href="https://www.youtube.com/channel/UCPWXiRWZ29zrxPFIQT7eHSA">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.washingtontimes.com"><strong class="iq co">Washington Times</strong></a> ($)
  — The other newspaper in Washington, DC. This daily was founded in 1982 at the end of the last red
  scare, by Sun Myung Moon, head of the Korea-based Unification Church, in a curious attempt to
  fight communism. In the intervening years it has become a leader of conservative and contrarian
  news publishing. <em class="jt">Paywall after 3 articles.</em><br />
  <strong class="iq co">Media Food Group:</strong> Independent Party Politics<br />
  <strong class="iq co">Viewpoint</strong>: Republican<br />
  <a href="https://www.washingtontimes.com">READ &gt;</a>
</p>

<p>
  <a href="https://www.wsj.com/"><strong class="iq co">Wall Street Journal</strong></a><strong
      class="iq co"> </strong>($) — One of the oldest American daily newspapers, the WSJ
  focuses first on business news, but has considerable political coverage and clout too, including a
  famously conservative editorial page. Owned by billionaire Rupert Murdoch’s News Corporation.
  Based in New York. <em class="jt">Paywall now after only one article.</em><br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Republican<br />
  <a href="https://www.wsj.com/">READ &gt;</a>
</p>

<p>
  <a href="https://en.wikipedia.org/wiki/List_of_newspapers_in_the_United_States"><strong
        class="iq co">Your local newspaper</strong></a>
  — Stay apprised of what’s going on in your town, city, and region. Notice your local paper’s bias.
  You probably have at least two local newspapers. Regardless of perspective, the closer journalism
  is to where you live, the more honest it will be about the events in your local community.<br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Republican or Democrat<br />
  <a href="https://en.wikipedia.org/wiki/List_of_newspapers_in_the_United_States">READ &gt;</a>
</p>
<h1>Individual Journalists &amp; Columnists</h1>

<p>
  <a href="https://sharylattkisson.com/"><strong class="iq co">Sheryl Attkisson
    </strong></a>—<strong class="iq co"> </strong>After three decades as a correspondent and anchor
  at PBS and CBS
  News, Attkisson resigned from CBS citing bias and a vanishing commitment to investigative
  journalism. She creates a weekly 20-minute investigative news show called
  <em class="jt">Full Measure</em>. Attkisson’s bias is mildly conservative but as a
  dyed-in-the-wool investigative journalist she lets the chips fall where they may.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Conservative<br />
  <a href="https://www.youtube.com/channel/UCxOEp-QgiQMwqBmJlBbgrJQ">WATCH &gt;</a> <br />
  <a href="https://sharylattkisson.com/">READ &gt;</a>
</p>

<p>
  <a href="https://thehighwire.com/watch/"><strong class="iq co">Del Bigtree / The
      Highwire</strong></a>
  —Bigtree’s weekly investigative tour de force <em class="jt">The Highwire</em> is a two-hour news
  show that will balance an entire week of NPR or CNN’s fear-based coverage of the lockdowns and the
  coronavirus. He goes hard but fair at the dominant paradigms about the virus, bringing on experts
  in the fields of immunology, virology, epidemiology, and others, like OSHA mask experts and
  doctors who have cured COVID-19 with hydroxychloroquine and ivermectin. Based in Austin, TX.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Conservative<br />
  <a href="https://thehighwire.com/watch/">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.youtube.com/channel/UCR0qQlskMnffysEChGKqFpQ"><strong class="iq co">Rachel
      Blevins</strong></a>
  — Progressive news commentator who, like Phil Donahue, Ed Schultz, Krystal Ball, etc., was fired
  for being too outspoken for corporate mainstream media. Her stated goal is to “look beyond the
  false ‘left vs. right’ paradigm that is prevalent in American media,” and pay attention to issues
  not covered elsewhere.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Progressive<br />
  <a href="https://www.youtube.com/channel/UCR0qQlskMnffysEChGKqFpQ">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.youtube.com/channel/UCyvaZ2RHEDrgKXz43gz7CbQ"><strong class="iq co">Lee Camp
      / Redacted Tonight</strong></a>
  — In a time of universal deception, comedy is often the only channel through which the truth comes
  out. In the tradition of George Carlin and Jon Stewart, the brilliant-yet-accessible deep politics
  commentator today is comedian Lee Camp and his weekly show Redacted Tonight. Foul language and
  off-color jokes abound, but so does a penetrating examination of the underlying forces of current
  American politics. <br />
  <strong class="iq co">Media Food Group:</strong> Deep Politics<br />
  <strong class="iq co">Viewpoint:</strong> Progressive<br />
  <a href="https://www.youtube.com/channel/UCyvaZ2RHEDrgKXz43gz7CbQ">WATCH &gt;</a>
</p>

<p>
  <a href="http://insider.foxnews.com/people/tucker-carlson"><strong class="iq co">Tucker
      Carlson</strong></a>
  — A conservative FOX News commentator who freely switches from toeing the party line to offering
  an independent take on current national politics. Speaks his own mind particularly on foreign
  policy. Rumor is, Carlson is the only news commentator Donald Trump watches.<br />
  <strong class="iq co">Media Food Group:</strong> Corporate Party Politics<br />
  <strong class="iq co">Viewpoint:</strong> Republican<br />
  <a href="http://insider.foxnews.com/people/tucker-carlson">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.youtube.com/channel/UC3M7l8ved_rYQ45AVzS0RGA"><strong class="iq co">Jimmy
      Dore </strong></a>— Another comedian-turned-news-commentator, Dore’s daily show provides
  in-depth coverage of the
  day’s events, from a progressive viewpoint, as well as cogent — even withering at times —
  criticism of the corporate media. His style is literally to analyze a few of the day’s corporate
  news reports and point out lies and deception. Great for media balance.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Progressive<br />
  <a href="https://www.youtube.com/channel/UC3M7l8ved_rYQ45AVzS0RGA">WATCH &gt;</a>
</p>

<p>
  <a href="https://greenwald.substack.com/"><strong class="iq co">Glenn Greenwald</strong></a>— The
  talented independent investigative journalist who provided Edward Snowden with his initial
  connections to the media to tell the world about massive American surveillance, Greenwald founded
  the <em class="jt">The Intercept</em> to provide censorship-free journalism opportunities to
  American writers, and then left the publication after five years when it “became its antithesis.”
  He’s now independent again and writing better than ever.<br />
  <strong class="iq co">Media Food Group: </strong>Independent True Politics<br />
  <strong class="iq co">Viewpoint: </strong>Progressive<br />
  <a href="https://theintercept.com/staff/glenn-greenwald/">READ &gt;</a>
</p>

<p>
  <a href="https://www.truthdig.com/author/chris_hedges/"><strong class="iq co">Christopher Hedges
    </strong></a>— Cogent, even caustic, left critic of capitalism and our current American system,
  which he
  calls, with formidable evidence, “corporate totalitarianism.” Fired by the
  <em class="jt">New York Times </em>for being insufficiently supportive of the 2003 invasion of
  Iraq, Hedges is one of several heirs apparent to Noam Chomsky and eminently worth listening
  to.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Progressive<br />
  <a href="https://www.truthdig.com/author/chris_hedges/">READ &gt;</a>
</p>

<p>
  <a href="https://www.youtube.com/channel/UCFAQyZq0NqfCwVkRQoXpx0A"><strong class="iq co">Niko
      House</strong></a>
  — An Army veteran who studied at UNC Chapel Hill, Niko House is a young independent journalist
  with a growing following. In 2016, he was the founder of “Carolina Students for Bernie Sanders,”
  and later revealed that the Vermont senator’s campaign in the Tar Heel State was derailed from
  within by Hillary Clinton supporters who ran the state campaign. Getting to the bottom of this
  scandal launched his career as a journalist, and he now doesn’t support either party but rather
  reports on the underlying forces driving the entire American political process.<br />
  <strong class="iq co">Media Food Group:</strong> Independent Deep Politics<br />
  <strong class="iq co">Viewpoint:</strong> Nonpartisan<br />
  <a href="https://www.youtube.com/channel/UCFAQyZq0NqfCwVkRQoXpx0A">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.youtube.com/channel/UCoJTOwZxbvq8Al8Qat2zgTA"><strong class="iq co">Kim
      Iversen</strong></a>
  —From a successful career on corporate radio stations all around the country to an independent
  show of her own, Iversen has found a following with her trenchant political analysis. One of many
  solo video bloggers today doing excellent independent journalism, her perspective defies easy
  labels: she has both conservative and progressive biases, depending on the issue, which suggests
  she has that rare quality, <em class="jt">integrity.</em><br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Progressive<br />
  <a href="https://www.youtube.com/channel/UCoJTOwZxbvq8Al8Qat2zgTA">WATCH &gt;</a>
</p>

<p>
  <a href="https://medium.com/@caityjohnstone"
      class="bp ju"
      rel="noopener"><strong class="iq co">Caitlin Johnstone</strong></a>
  — Uncommonly insightful independent writer with a deep analytical perspective that is nearly
  unavailable elsewhere. She points out the flaws and corruption in both parties and explores the
  underlying forces directing both. Be forewarned: she peppers her political commentary with
  sardonic humor, four-letter words, and occasional appeals to universal spirituality.<br />
  <strong class="iq co">Media Food Group: </strong>Independent Deep Politics<br />
  <strong class="iq co">Viewpoint: </strong>Progressive<br />
  <a href="https://medium.com/@caityjohnstone"
      class="bp ju"
      rel="noopener">READ &gt;</a>
</p>

<p>
  <a href="https://thegrayzone.com/category/pushback/"><strong class="iq co">Aaron Maté / The
      Grayzone</strong></a>
  — An independent journalist with a keen eye for uncovering media distortion in foreign affairs
  reporting, Maté has provided balance and clarity on the endless Russiagate conspiracy theory, the
  alleged gas attacks in Syria, and the frequently censored genocide going on in Yemen. He’s written
  for years for <em class="jt">The Nation</em> (above), and recently joined independent news
  analysis network The Grayzone with fellow journalists Max Blumenthal and Anya Parampil.<br />
  <strong class="iq co">Media Food Group: </strong>Independent Deep Politics<br />
  <strong class="iq co">Viewpoint: </strong>Progressive<br />
  <a href="https://thegrayzone.com/category/pushback/">WATCH &gt;</a>
</p>

<p>
  <a href="https://www.youtube.com/channel/UCMxiv15iK_MFayY_3fU9loQ"><strong class="iq co">Freddie
      Sayers / Unherd</strong></a>— British journalist Freddie Sayers creates high-quality, often
  superb, interviews, and as
  balanced information on the pandemic, virus, and lockdowns grew difficult to find throughout 2020,
  <em class="jt">Unherd</em> featured political leaders and expert scientists on all sides of the
  questions. Indeed it was perhaps as un-2020 as news sources came, featuring long, thoughtful
  conversations rather than fearful soundbites. Sayers continues his excellent work in 2021,
  striving for balanced and patient interviews.<br />
  <strong class="iq co">Media Food Group: </strong>Foreign News<strong class="iq co">
  </strong><br />
  <strong class="iq co">Viewpoint: </strong>Nonpartisan <br />
  <a href="https://www.youtube.com/channel/UCMxiv15iK_MFayY_3fU9loQ">WATCH &gt;</a>
</p>

<p>
  <a href="https://dossier.substack.com/"><strong class="iq co">Jordan Schachtel </strong></a>— An
  indefatigable investigative journalist with a foreign affairs focus and a conservative slant,
  Schachtel left Breitbart News in 2016 on principle, alleging incessant “party-line Trump
  propaganda.” In 2020, he did brilliant investigative work countering what he sees as groupthink on
  the lockdowns and coronavirus response, and provided important perspective on the Capitol protests
  in early 2021. Shachtel’s reporting and analysis will prove important in 2021 for balancing
  corporate news bias.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Conservative<br />
  <a href="https://dossier.substack.com/">READ &gt;</a>
</p>

<p>
  <strong class="iq co">Ben Swann </strong>— A quintessential investigative journalist, Swann
  founded an investigative segment called <em class="jt">Reality Check </em>and a social media
  channel called <em class="jt">Truth in Media</em> while working in corporate news television in
  Atlanta and Cincinnati. He anchored these segments for years and won nationwide praise for asking
  Obama difficult questions about drone strikes in an interview. He was then fired for investigating
  “Pizzagate” in 2018. Now on his own, his <em class="jt">Reality Check</em> show provides great
  counterpoint to corporate media news.<br />
  <strong class="iq co">Media Food Group:</strong> Independent True Politics<br />
  <strong class="iq co">Viewpoint:</strong> Conservative<br />
  <a href="https://dossier.substack.com/">READ &gt;</a>
</p>

<p>
  <em>And that’s it. A quick note on something I’ve barely mentioned: money. While nearly all
    sources
    selected are free to read or view, a few have instituted a partial paywall; these are marked
    with a dollar sign ($). For these, read the number of articles allowed, then move on to the next
    source in the day’s diet. You can also choose to pay the subscription fee, of course, but in
    that case try to contribute equally to all sources you go to for news, or at least balance your
    contributions somewhat, as many of the independent journalists offer their writing for free as
    they attempt to build a large audience. The funding of quality journalism is a complex and
    contentious issue, something I’ll address in a future article.</em>
</p>
