<div nz-row
    class="main-content page-top bg-triangle">
  <div nz-col
      nzMd="12"
      nzSm="24"
      nzXs="24">

    <section id="home">
      <h1 id="home-header"><span class="text-bmd-red">WELCOME TO THE BALANCED MEDIA DIET</span>
      </h1>
      <h2 class="sqsrte-fadeIn"><span class="text-bmd-blue"><strong>IN A TIME OF RANCOR AND
            DIVISION, BE A
            BEACON OF SANITY AND AWARENESS.</strong></span></h2>
      <p class="sqsrte-fadeIn"
          style="transition-delay: 0.0524691s">Rather than languish in a Left or Right echo chamber,
        read a balanced set of news sources. Think independently and come to
        <i>your</i> opinions
        rather than those assigned to you by one party or publisher. Gain a new
        outlook on world events, build and broaden
        your mind, and understand the political views of virtually anyone you meet.
        <!--  This is a journey,
        an adventure, like international travel. It is a mental technique, a new approach to listening, a method to grow smarter, and a long-term plan for making
        better
        decisions in your life.</p> -->
        <h2 class="sqsrte-fadeIn"><span class="text-bmd-blue"><strong>40 SOURCES, 30 MINUTES, 20
              DOLLARS.</strong></span></h2>
        <p class="sqsrte-fadeIn">The diet and everything on this
          website are completely free, but we recommend setting aside a small amount each
          month to contribute to journalists you appreciate so as to build a new media
          economy. As for time, devote thirty minutes or more each day, depending on which diet you
          choose.
          There are forty sources featured in the <a (click)="scroll('Expert')"
              class="underline uncolored">Expert Diet</a>,
          twenty-seven in the <a (click)="scroll('Intermediate')"
              class="underline uncolored">Intermediate</a>, and thirteen in
          the <a (click)="scroll('Basic')"
              class="underline uncolored">Basic Diet</a>.
        </p>
        <p></p>

        <div class="fe-block fe-block-feca87e66e60df57b25d">
          <button nz-button
              (click)="scroll('the-diets')"
              nzType="primary">2023 Recommended Diets</button>
        </div>

    </section>
  </div>

  <div nz-col
      nzMd="12"
      nzSm="0"
      nzXs="0"
      id="iphone-image-container">
    <img src="../../assets/iphone.png">
  </div>
</div>

<div class="bg-pyramid-mobile mobile-only"></div>

<h1 class="text-bmd-blue text-center">THE SIX MEDIA FOOD GROUPS</h1>

<div nz-row
    class="bg-pyramid-desktop">
  <div nz-col
      nzMd="8"
      nzSm="0"
      nzXs="0"></div>
  <div nz-col
      nzMd="16"
      nzSm="24"
      nzXs="24">

    <section class="main-content">


      <h2><span class="text-bmd-red">1. FOREIGN NEWS</span></h2>
      <p>
        This group includes all news and viewpoints originating from outside the United States,
        particularly from non-English speaking countries. Consuming media from this group is
        critical for reaching an informed opinion not just as a citizen of the country but as a
        member of the human race.
      </p>
      <h2><span class="text-bmd-red">2. PARTY POLITICS: DEMOCRAT</span></h2>
      <p>
        News and viewpoints that follow the daily and weekly news cycle of events, scandals, and
        legislation on the Democrat side. Important to understanding how news is packaged to inform
        and outrage this party. Generally, the blame is placed on the other party, and there is
        little
        deeper analysis.
      </p>
      <h2><span class="text-bmd-red">3. PARTY POLITICS: REPUBLICAN</span></h2>
      <p>
        News and viewpoints that follow the daily and weekly news cycle of events, scandals, and
        legislation on the Republican side. Important to understanding how news is packaged to
        inform and outrage this party. Generally, the blame is placed on the other party, and
        there is little deeper analysis.
      </p>
      <h2><span class="text-bmd-red">4. TRUE POLITICS: PROGRESSIVE & LIBERAL</span></h2>
      <p>
        News and analysis that will point out flaws in both parties and that will sometimes suggest
        that the parties agree about most things and feign disagreement. Progressive analysis
        includes class analysis that acknowledges that all Americans suffer when there are wars
        overseas, no universal healthcare, starvation wages, polluted air and water, and crippling
        debt. Progressive True Politics sources tend to be marginalized by Party Politics sources
        with these labels: <i>“radical,” “socialist,” “far-left,” “communist.”</i>
      </p>
      <h2><span class="text-bmd-red">5. TRUE POLITICS: LIBERTARIAN & CONSERVATIVE</span></h2>
      <p>
        News and analysis that will point out flaws in both parties and that will sometimes suggest
        that the parties agree about most things and feign disagreement. Libertarian perspectives
        include analysis of privacy and liberty that critiques the encroaching surveillance state,
        never-ending wars, and our shrinking set of personal rights. Conservative perspectives
        include attention to founding principles and favor deliberate consideration before
        advocating systemic change. Words used by Party Politics sources to marginalize and silence
        these sources: <i>“radical,” “fascist,” “Nazi,” “alt-right.”</i>
      </p>
      <h2><span class="text-bmd-red">6. DEEP POLITICS</span></h2>
      <p>
        News and analysis covering our country’s underlying power structures. Examining
        policies—particularly hidden ones—about wars, energy resources, the money supply, drugs, and
        surveillance that are often constructed by unelected officials permanently ensconced in
        intelligence organizations, the Pentagon, and other executive departments. Words used to
        marginalize and silence these sources: <i>“paranoid,” “wacky,” “conspiracy theorist.”</i>
      </p>

    </section>
  </div>
</div>
<div nz-row>

  <section id="the-diets"
      class="main-content bg-reverse text-center full-width-section">
    <h1><span class="text-bmd-white">THE DIETS</span></h1>
    <h2><span class="text-bmd-red">VIEW <span class="text-bmd-white large-text">2</span> EPISODES OR
        ARTICLES<br>FROM THE DAY'S SOURCES</span></h2>
    <p>The diets are below. There
      are three levels, so pick which best fits your appetite. How much time will
      you spend? </p>
    <ul>
      <li><a (click)="scroll('Basic')"
            class="uncolored underline">30 minutes</a> each day will
        tremendously impact your awareness of the country and world around us.</li>
      <li>Step it up to <a (click)="scroll('Intermediate')"
            class="uncolored underline">60 minutes</a> for greater depth with the “Informed
        Journalist” diet.
      </li>
      <li>Devote <a (click)="scroll('Expert')"
            class="uncolored underline">two hours</a> each day to become an expert “Media
        Critic” and accelerate your journey to <em>media consciousness</em>.</li>
    </ul>
  </section>
</div>

<a name="diet-grid"
    id="diet-grid"></a>

<!--- BASIC --->
<diet-grid [dietId]="0"></diet-grid>

<!--- INTERMEDIATE --->
<diet-grid [dietId]="1"></diet-grid>

<!--- ADVANCED --->
<diet-grid [dietId]="2"></diet-grid>
