import { HomeComponent } from './home/home.component';
import { GuideComponent } from './guide/guide.component';
import { AboutComponent } from './about/about.component';
import { TodayComponent } from './today/today.component';
import { NewsSourcesComponent } from './news-sources/news-sources.component';
import { FoodGroupsComponent } from './food-groups/food-groups.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsSourceComponent } from './news-source/news-source.component';
import { DietGridComponent } from './diet-grid/diet-grid.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },

  // { path: 'news-source/:id', component: NewsSourceComponent },
  // { path: 'home', component: HomeComponent },
  // { path: 'diets', component: DietGridComponent },
  // { path: 'food-groups', component: FoodGroupsComponent },
  // { path: 'news-sources', component: NewsSourcesComponent },
  // { path: 'today', component: TodayComponent },
  // { path: 'guide', component: GuideComponent },
  // { path: 'about', component: AboutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
